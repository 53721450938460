import { Typography } from '@material-tailwind/react';
import React from 'react'
const MissionImage = '../assets/misi.svg';



const VisionMissionNew = ({resultAboutUs,loadingResultAboutUs,en,styles}) => {
  return (
    <div className="relative grid grid-cols-1 md:grid-cols-2 h-96 mt-14">
      {/* Bagian kiri (50%) */}
      <div style={{ backgroundColor: styles.primaryColor }} className={`text-white flex flex-col justify-center p-16 md:p-0 md:px-56`}>
        <div className="font-bold text-xl mb-4">{en ? 'Vision' : 'Visi'}</div>
        {loadingResultAboutUs ? (
          <>
          <Typography
                as="div"
                variant="h1"
                className="mb-8 h-4  rounded-full bg-gray-300"
            >
                &nbsp;
            </Typography>
            <Typography
                as="div"
                variant="paragraph"
                className="mb-2 h-3 w-[85%] rounded-full bg-gray-300"
            >
                &nbsp;
            </Typography>
            <Typography
                as="div"
                variant="paragraph"
                className="mb-2 h-3 w-[85%] rounded-full bg-gray-300"
            >
                &nbsp;
            </Typography>
          </>
        ) : (
          <p className="text-sm" dangerouslySetInnerHTML={{__html: resultAboutUs.visi}}></p>
        )} 
      </div>

      {/* Bagian kanan (50%) */}
      <div className="bg-gray-600 text-white flex flex-col justify-center p-16 md:p-0 md:px-56">
        <div className="font-bold text-xl mb-4">Misi</div>
        {loadingResultAboutUs ? (
          <>
            <Typography
                as="div"
                variant="h1"
                className="mb-8 h-4  rounded-full bg-gray-300"
            >
                &nbsp;
            </Typography>
            <Typography
                as="div"
                variant="paragraph"
                className="mb-2 h-3 w-[85%] rounded-full bg-gray-300"
            >
                &nbsp;
            </Typography>
            <Typography
                as="div"
                variant="paragraph"
                className="mb-2 h-3 w-[85%] rounded-full bg-gray-300"
            >
                &nbsp;
            </Typography>
            <Typography
                as="div"
                variant="paragraph"
                className="mb-2 h-3 w-[85%] rounded-full bg-gray-300"
            >
                &nbsp;
            </Typography>
            <Typography
                as="div"
                variant="paragraph"
                className="mb-2 h-3 w-[85%] rounded-full bg-gray-300"
            >
                &nbsp;
            </Typography>
            <Typography
                as="div"
                variant="paragraph"
                className="mb-2 h-3 w-[85%] rounded-full bg-gray-300"
            >
                &nbsp;
            </Typography>
          </>
        ) : (
          <div className="text-sm space-y-2">
            <p dangerouslySetInnerHTML={{__html: resultAboutUs.misi}}></p>
          </div>
        )}
      </div>

      {/* Gambar absolute di tengah */}
      <div className="absolute bottom-0  left-1/2 transform -translate-x-1/2 h-full z-50 hidden md:flex items-center justify-end">
        <img 
          src={MissionImage} 
          alt="Vision" 
          className="h-64 w-auto object-cover z-20"
        />
      </div>
    </div>

  )
}

export default VisionMissionNew
