import React from 'react'
import {
  Button,
  Typography,
} from "@material-tailwind/react";
import { FaWhatsapp } from 'react-icons/fa';

const banner = '../assets/tesBanner4.png'

const BannerNew = ({result,resultAboutUs,en,loadingResult,loadingResultAboutUs,resultContactUs}) => {
  return (
    <>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-32 mt-16 px-16 md:px-32 items-center">
            <div>
                {loadingResultAboutUs ? (
                    <div className="max-w-full animate-pulse">
                        <Typography
                            as="div"
                            variant="h1"
                            className="mb-8 h-4  rounded-full bg-gray-300"
                        >
                            &nbsp;
                        </Typography>
                        <Typography
                            as="div"
                            variant="paragraph"
                            className="mb-2 h-3 w-[85%] rounded-full bg-gray-300"
                        >
                            &nbsp;
                        </Typography>
                        <Typography
                            as="div"
                            variant="paragraph"
                            className="mb-2 h-3 w-[85%] rounded-full bg-gray-300"
                        >
                            &nbsp;
                        </Typography>
                        <Typography
                            as="div"
                            variant="paragraph"
                            className="mb-2 h-3 w-[85%] rounded-full bg-gray-300"
                        >
                            &nbsp;
                        </Typography>
                        <Typography
                            as="div"
                            variant="paragraph"
                            className="mb-5 h-3 w-[85%] rounded-full bg-gray-300"
                        >
                            &nbsp;
                        </Typography>
                        <Typography
                            as="div"
                            variant="paragraph"
                            className="mb-2 h-3 w-[70%] rounded-full bg-gray-300"
                        >
                            &nbsp;
                        </Typography>
                        <Typography
                            as="div"
                            variant="paragraph"
                            className="mb-2 h-3 w-[70%] rounded-full bg-gray-300"
                        >
                            &nbsp;
                        </Typography>
                        <Typography
                            as="div"
                            variant="paragraph"
                            className="mb-2 h-3 w-[70%] rounded-full bg-gray-300"
                        >
                            &nbsp;
                        </Typography>
                        <Typography
                            as="div"
                            variant="paragraph"
                            className="mb-2 h-3 w-[70%] rounded-full bg-gray-300"
                        >
                            &nbsp;
                        </Typography>
                    </div>
                ) : (
                    <>
                        <div className='mb-3 fw-bolder text-2xl'>{resultAboutUs.name ?? ''}</div>
                        <div dangerouslySetInnerHTML={{__html: (en? resultAboutUs.description_en : resultAboutUs.description)}}/>
                        <div className="flex items-center gap-4 mt-8">
                            <a
                            href={`https://web.whatsapp.com/send?phone=${resultContactUs.mobile_phone}&text=${encodeURIComponent(
                                "Saya berminat untuk kerjasama dengan perusahaan Fitek, tolong berikan harga terbaiknya."
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                            <Button variant='filled' color='green' className="flex items-center gap-3">
                                <FaWhatsapp size={15} color='white'/>
                                {en ? 'Contact us on WhatsApp' : 'Hubungi Kami di WhatsApp!'}
                            </Button>
                            </a>
                            <a href='#portfolio'>
                            <Button variant="text" className="flex items-center gap-2">
                                {en ? 'Our portfolio' : 'Portofolio kami'}{" "}
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={2}
                                stroke="currentColor"
                                className="h-5 w-5"
                                >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                />
                                </svg>
                            </Button>
                            </a>
                        </div>
                    </>
                )}
            </div>
            <div>
                {loadingResult ? (
                    <div className="grid h-[260px] w-full animate-pulse place-items-center rounded-lg bg-gray-300">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="h-12 w-12 text-gray-500"
                            >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                            />
                        </svg>
                    </div>
                ) : (
                    <img src={`https://cmsfg.fhadirainovasiteknologi.id/assets/images/partner/${result.file}`} alt="Banner" />
                )}
            </div>
        </div>
    </>
  )
}

export default BannerNew