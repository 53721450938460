export default function Banner({result, resultAboutUs, en}){

  const BgImage = result.file ? `url(https://cmsfg.fhadirainovasiteknologi.id/assets/images/partner/${result.file})` : `url(https://cmsfg.fhadirainovasiteknologi.id/assets/images/aboutUs/${resultAboutUs.banner_2})`

  return (
    <>
      <div id="banner" style={{backgroundImage: `url(https://cmsfg.fhadirainovasiteknologi.id/assets/images/partner/${result.file})`}} className="relative h-screen bg-transparent flex justify-center items-center flex-col bg-cover bg-center -z-20" >
        <img src={`https://cmsfg.fhadirainovasiteknologi.id/assets/images/partner/${result.file}`} alt="" className="absolute" />
        <div className="z-10">
          <h1 className="capitalize text-white font-bold text-center text-3xl lg:text-5xl">
            { en ? 'With Fhadira Group' : 'Bersama Fhadira Group' }
          </h1>
          <h1 className="capitalize text-white font-bold text-center text-3xl lg:text-5xl">
            { en ? 'Build a Nation' : 'Membangun Negeri' }
          </h1>
        </div>
      </div>
    </>
  )
}