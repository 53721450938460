import React from 'react'
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Typography,
} from "@material-tailwind/react";

const CUSTOM_ANIMATION = {
  mount: { scale: 1 },
  unmount: { scale: 0.9 },
};

const FaqNew = ({loadingFaq,resultFaq,en,styles}) => {
    const [open, setOpen] = React.useState(0);
    const handleOpen = (value) => setOpen(open === value ? 0 : value);

  return (
    <div style={{ backgroundColor: styles.primaryColor }} className={`mt-16 px-16 md:px-32 py-10 text-white`}>
        
        <div id='Faq' className='text-center text-2xl font-bold mb-2'>FAQ</div>
          {loadingFaq ? (
            <>
              <Typography
                  as="div"
                  variant="h1"
                  className="mb-8 h-4  rounded-full bg-gray-300"
              >
                  &nbsp;
              </Typography>
              <Typography
                  as="div"
                  variant="paragraph"
                  className="mb-2 h-3 w-[85%] rounded-full bg-gray-300"
              >
                  &nbsp;
              </Typography>
              <Typography
                  as="div"
                  variant="paragraph"
                  className="mb-2 h-3 w-[85%] rounded-full bg-gray-300"
              >
                  &nbsp;
              </Typography>
              <Typography
                  as="div"
                  variant="paragraph"
                  className="mb-2 h-3 w-[85%] rounded-full bg-gray-300"
              >
                  &nbsp;
              </Typography>
              <Typography
                  as="div"
                  variant="paragraph"
                  className="mb-2 h-3 w-[85%] rounded-full bg-gray-300"
              >
                  &nbsp;
              </Typography>
              <Typography
                  as="div"
                  variant="paragraph"
                  className="mb-2 h-3 w-[85%] rounded-full bg-gray-300"
              >
                  &nbsp;
              </Typography>
            </>
          ) : (
            <>
            {resultFaq.length > 0? (
              resultFaq.map((item, index) => (
                <Accordion open={open === index + 1} animate={CUSTOM_ANIMATION} key={index}>
                  <AccordionHeader onClick={() => handleOpen(index + 1)}>{en ? item.question_en : item.question}</AccordionHeader>
                  <AccordionBody className="text-white">
                    {en ? item.answer_en : item.answer}
                  </AccordionBody>
              </Accordion>
              ))
            ): (
              <div className="text-center col-span-3 text-2xl font-bold mb-10">
                {en
                    ? "There are no FAQs available yet"
                    : "Belum ada FAQs yang tersedia"}
                </div>
            )}
            </>
          )}
    </div>
  )
}

export default FaqNew