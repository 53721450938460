import React from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button
} from "@material-tailwind/react";
import { format } from "date-fns";
import { Link } from 'react-router-dom';

const NewsNew = ({resultArticles,loadingArticles,en}) => {
  return (
    <div className='mt-16 px-16 md:px-32'>
        <div id='news' className='text-center text-2xl font-bold mb-10'>News</div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
            {loadingArticles ? (
                <>
                    <Card className="mt-6 w-96 animate-pulse">
                        <CardHeader
                            shadow={false}
                            floated={false}
                            className="relative grid h-56 place-items-center bg-gray-300"
                        >
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="h-12 w-12 text-gray-500"
                            >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                            />
                            </svg>
                        </CardHeader>
                        <CardBody>
                            <Typography
                            as="div"
                            variant="h1"
                            className="mb-4 h-3 w-56 rounded-full bg-gray-300"
                            >
                            &nbsp;
                            </Typography>
                            <Typography
                            as="div"
                            variant="paragraph"
                            className="mb-2 h-2 w-full rounded-full bg-gray-300"
                            >
                            &nbsp;
                            </Typography>
                            <Typography
                            as="div"
                            variant="paragraph"
                            className="mb-2 h-2 w-full rounded-full bg-gray-300"
                            >
                            &nbsp;
                            </Typography>
                            <Typography
                            as="div"
                            variant="paragraph"
                            className="mb-2 h-2 w-full rounded-full bg-gray-300"
                            >
                            &nbsp;
                            </Typography>
                            <Typography
                            as="div"
                            variant="paragraph"
                            className="mb-2 h-2 w-full rounded-full bg-gray-300"
                            >
                            &nbsp;
                            </Typography>
                        </CardBody>
                        <CardFooter className="pt-0">
                            <Button
                            disabled
                            tabIndex={-1}
                            className="h-8 w-20 bg-gray-300 shadow-none hover:shadow-none"
                            >
                            &nbsp;
                            </Button>
                        </CardFooter>
                    </Card>
                    <Card className="mt-6 w-96 animate-pulse">
                        <CardHeader
                            shadow={false}
                            floated={false}
                            className="relative grid h-56 place-items-center bg-gray-300"
                        >
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="h-12 w-12 text-gray-500"
                            >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                            />
                            </svg>
                        </CardHeader>
                        <CardBody>
                            <Typography
                            as="div"
                            variant="h1"
                            className="mb-4 h-3 w-56 rounded-full bg-gray-300"
                            >
                            &nbsp;
                            </Typography>
                            <Typography
                            as="div"
                            variant="paragraph"
                            className="mb-2 h-2 w-full rounded-full bg-gray-300"
                            >
                            &nbsp;
                            </Typography>
                            <Typography
                            as="div"
                            variant="paragraph"
                            className="mb-2 h-2 w-full rounded-full bg-gray-300"
                            >
                            &nbsp;
                            </Typography>
                            <Typography
                            as="div"
                            variant="paragraph"
                            className="mb-2 h-2 w-full rounded-full bg-gray-300"
                            >
                            &nbsp;
                            </Typography>
                            <Typography
                            as="div"
                            variant="paragraph"
                            className="mb-2 h-2 w-full rounded-full bg-gray-300"
                            >
                            &nbsp;
                            </Typography>
                        </CardBody>
                        <CardFooter className="pt-0">
                            <Button
                            disabled
                            tabIndex={-1}
                            className="h-8 w-20 bg-gray-300 shadow-none hover:shadow-none"
                            >
                            &nbsp;
                            </Button>
                        </CardFooter>
                    </Card>
                    <Card className="mt-6 w-96 animate-pulse">
                        <CardHeader
                            shadow={false}
                            floated={false}
                            className="relative grid h-56 place-items-center bg-gray-300"
                        >
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="h-12 w-12 text-gray-500"
                            >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                            />
                            </svg>
                        </CardHeader>
                        <CardBody>
                            <Typography
                            as="div"
                            variant="h1"
                            className="mb-4 h-3 w-56 rounded-full bg-gray-300"
                            >
                            &nbsp;
                            </Typography>
                            <Typography
                            as="div"
                            variant="paragraph"
                            className="mb-2 h-2 w-full rounded-full bg-gray-300"
                            >
                            &nbsp;
                            </Typography>
                            <Typography
                            as="div"
                            variant="paragraph"
                            className="mb-2 h-2 w-full rounded-full bg-gray-300"
                            >
                            &nbsp;
                            </Typography>
                            <Typography
                            as="div"
                            variant="paragraph"
                            className="mb-2 h-2 w-full rounded-full bg-gray-300"
                            >
                            &nbsp;
                            </Typography>
                            <Typography
                            as="div"
                            variant="paragraph"
                            className="mb-2 h-2 w-full rounded-full bg-gray-300"
                            >
                            &nbsp;
                            </Typography>
                        </CardBody>
                        <CardFooter className="pt-0">
                            <Button
                            disabled
                            tabIndex={-1}
                            className="h-8 w-20 bg-gray-300 shadow-none hover:shadow-none"
                            >
                            &nbsp;
                            </Button>
                        </CardFooter>
                    </Card>
                </>
            ) : (
                <>
                {resultArticles.length > 0 ? (
                    resultArticles.map((article, index) => (
                        <Card className="max-w-[24rem] overflow-hidden" key={index}>
                            <CardHeader
                                floated={false}
                                shadow={false}
                                color="transparent"
                                className="m-0 rounded-none"
                            >
                                <img
                                src={`https://cmsfg.fhadirainovasiteknologi.id/assets/images/news/${article.file}`}
                                alt="ui/ux review check"
                                />
                            </CardHeader>
                            <CardBody>
                                <Link to={`/Fitek/news/${article.slug}`}>
                                <Typography variant="h4" color="blue-gray">
                                {article.name ?? '-'}
                                </Typography>
                                </Link>
                                <Typography variant="lead" color="gray" className="mt-3 font-normal" dangerouslySetInnerHTML={{ __html: article.short_description ?? '-' }}></Typography>
                            </CardBody>
                            <CardFooter className="flex items-center justify-between">
                                <div className="flex items-center -space-x-3">
                                <Link to={`/Fitek/news/${article.slug}`}>
                                    <Button>{en ? 'Read More' : 'lihat Selengkapnya'}</Button>
                                </Link>
                                </div>
                                <Typography className="font-normal">{format(new Date(article.created_at), "dd MMMM")}</Typography>
                            </CardFooter>
                        </Card>
                    ))
                ) : (
                    <div className="text-center col-span-3 text-2xl font-bold mb-10">
                    {en
                        ? "There are no news available yet"
                        : "Belum ada berita yang tersedia"}
                    </div>
                )}
                </>
                
            )}
            
        </div>
    </div>
  )
}

export default NewsNew