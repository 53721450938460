import AboutUsImage from "../img/Kayoko-lenslans00000-28_04_2024.jpg"

export default function AboutUs({resultAboutUs, en = false}){
  return (
    <>
      <div className="flex flex-row justify-center items-center h-screen">
        <div data-aos="fade-right" className="basis-1/2 lg:flex lg:justify-center hidden">
          <img src={`https://cmsfg.fhadirainovasiteknologi.id/assets/images/aboutUs/${resultAboutUs.pict}`} alt="" className="w-2/3" />
        </div>
        <div data-aos="fade-left" className="basis-1/2 px-1 lg:px-8 text-justify">
          <h2 className="font-bold text-2xl my-2 text-center">
            { en ? 'About Us' : 'Tentang Kami' }
          </h2>
          <p className="my-2">
            <div dangerouslySetInnerHTML={{__html: (en? resultAboutUs.description_en : resultAboutUs.description)}}/>
          </p>
        </div>
      </div>
    </>
  )
}