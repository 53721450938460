import PortfolioImage from "../../component/PortfolioImage";
import PortfolioInformation from "../../component/PortfolioInformation";
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

import Image from '../../img/Kayoko-lenslans00000-28_04_2024.jpg'

const api = axios.create({
  baseURL: `https://cmsfg.fhadirainovasiteknologi.id/api`
})

export default function PortfolioPage(){

  const [result, setResult] = useState([]);
  
  let { namapartner } = useParams();
  
  const isMobile = window.innerWidth < 768;
  
  const styles = {
    primaryColor: `${result.primary_color}`,
    secondaryColor: `${result.secondary_color}`,
    // ... other styles
  };

  useEffect(() => {
    // Fungsi untuk memanggil API
    const fetchData = async () => {
        try {
            namapartner = namapartner ?? 'Fitek';
            // console.log('nama partner: ' + namapartner);
            const response = await api.get('/partners?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel= ' + `${namapartner}`); // Ganti '/endpoint' dengan path API yang sesuai
            setResult(response.data.data); // Mengatur hasil response ke state
            // console.log(response.data);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

      // Panggil fungsi fetch saat komponen di-mount
      fetchData();
      window.scrollTo(0, 0)
  }, []); // Dependensi kosong agar hanya dipanggil sekali saat komponen di-mount

  return (
    <>
      <div style={{backgroundImage: `url(${Image})`}}  className="h-screen bg-cover" >

      </div>
      {/* <img src={Image} alt="" className="w-screen bg-cover" /> */}
      <PortfolioInformation styles={styles}></PortfolioInformation>
    </>
  )
}