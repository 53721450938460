import React, { useState } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Typography,
  Avatar,
} from "@material-tailwind/react";

const PortofolioNew = ({loadingPorto,resultPorto,en,resultAboutUs}) => {
    const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (porto) => {
    setSelectedImage(porto);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };
  return (
    <div className='mt-72 md:mt-16 px-10 md:px-32' id='portfolio'>
        
        <div className="grid grid-cols-1 md:gap-0 gap-4 md:grid-cols-1">
            {loadingPorto ? (
                <>
                    <Card className="mt-6 w-1/2 mx-auto animate-pulse">
                        <CardHeader
                            shadow={false}
                            floated={false}
                            className="relative grid h-56 place-items-center bg-gray-300"
                        >
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="h-12 w-12 text-gray-500"
                            >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                            />
                            </svg>
                        </CardHeader>
                        <CardBody>
                            <Typography
                            as="div"
                            variant="h1"
                            className="mb-4 h-3 w-56 rounded-full bg-gray-300"
                            >
                            &nbsp;
                            </Typography>
                            <Typography
                            as="div"
                            variant="paragraph"
                            className="mb-2 h-2 w-full rounded-full bg-gray-300"
                            >
                            &nbsp;
                            </Typography>
                            <Typography
                            as="div"
                            variant="paragraph"
                            className="mb-2 h-2 w-full rounded-full bg-gray-300"
                            >
                            &nbsp;
                            </Typography>
                            <Typography
                            as="div"
                            variant="paragraph"
                            className="mb-2 h-2 w-full rounded-full bg-gray-300"
                            >
                            &nbsp;
                            </Typography>
                            <Typography
                            as="div"
                            variant="paragraph"
                            className="mb-2 h-2 w-full rounded-full bg-gray-300"
                            >
                            &nbsp;
                            </Typography>
                        </CardBody>
                        <CardFooter className="pt-0">
                            <Button
                            disabled
                            tabIndex={-1}
                            className="h-8 w-20 bg-gray-300 shadow-none hover:shadow-none"
                            >
                            &nbsp;
                            </Button>
                        </CardFooter>
                    </Card>
                </>
            ) : (
                <div className="flex justify-center items-center min-h-screen bg-gray-100 w-full md:w-[50%] mx-auto">
                    <div className="overflow-hidden border border-gray-300 shadow-sm bg-white rounded-lg">
                        <div className="p-4">
                        <h2 className="text-blue-gray-700 mb-1 text-base font-semibold">#1</h2>
                        <div className='text-2xl font-bold md:mb-5'>Portofolio</div>
                        <div className="my-4 flex items-start justify-between">
                            <div className="flex items-center gap-3">
                            <div>
                                <h3 className="text-blue-gray-700 text-lg font-semibold">
                                {resultAboutUs.name ?? "-"}
                                </h3>
                                <p className="text-gray-500 font-medium">Creator</p>
                            </div>
                            </div>
                            <img
                            src={`https://cmsfg.fhadirainovasiteknologi.id/assets/images/aboutUs/${resultAboutUs.logo}`}
                            alt="Fitek"
                            width={70}
                            className="rounded"
                            />
                        </div>
                        <div className="grid grid-cols-3 gap-2">
                            {resultPorto.map((porto, index) => (
                            <img
                                key={index}
                                src={`https://cmsfg.fhadirainovasiteknologi.id/assets/images/products/${porto.file}`}
                                className="h-full w-full rounded-xl object-cover cursor-pointer"
                                alt="img"
                                onClick={() => openModal(porto)}
                            />
                            ))}
                        </div>
                        </div>
                    </div>

                {/* Modal */}
                {selectedImage && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                            <button
                            className=" text-gray-500 hover:text-gray-800"
                            onClick={closeModal}
                            >
                            ✕
                            </button>
                            <img
                            src={`https://cmsfg.fhadirainovasiteknologi.id/assets/images/products/${selectedImage.file}`}
                            alt="Selected"
                            className="w-full h-64 object-cover rounded-lg mb-4"
                            />
                            <h3 className="text-lg font-semibold text-blue-gray-700">
                            {selectedImage.name || "Image Name"}
                            </h3>
                            <p className="text-gray-600 mt-5" dangerouslySetInnerHTML={{__html: en ? selectedImage.description_en : selectedImage.description }}></p>
                            <Button className="mt-4 text-center mx-auto" onClick={closeModal}>Close</Button>
                        </div>
                    </div>
                )}
        </div>
            )}
        </div>
    </div>
  )
}

export default PortofolioNew