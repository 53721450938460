import React, { useState } from 'react'

const packages = [
  {
    name: "Wordpress Maniac",
    description: "Cocok untuk website personal atau portofolio sederhana.",
    features: [
      "Wordpress",
      "1 Programmer Profesional",
      "Domain gratis 1 tahun",
      "Hosting gratis 1 tahun",
      "Desain responsif",
      "4 Revisi Desain",
      "Support teknis 1 bulan",
    ],
    price: "Rp 1.500.000*",
    discountPrice: "Rp 1.000.000",
    phoneNumber: '628113117113',
    message: "Halo, saya tertarik dengan paket Wordpress Maniac. Mohon informasi lebih lanjut."
  },
  {
    name: "Website Maniac",
    description: "Ideal untuk bisnis kecil dan menengah.",
    features: [
      "1 Programmer Back End - Laravel,Go",
      "1 Programmer Front End - ReactJS,Jquery",
      "1 UI/UX Designer",
      "Domain gratis 1 tahun",
      "Hosting gratis 1 tahun",
      "Desain responsif",
      "Support teknis 2 bulan",
      "Integrasi API dan fitur custom",
      "SEO dasar",
    ],
    price: "Rp 8.000.000*",
    discountPrice: "Rp 9.000.000",
    phoneNumber: '628113117113',
    message: "Halo, saya tertarik dengan paket Website Maniac. Mohon informasi lebih lanjut."
  },
  {
    name: "Mobile Maniac",
    description: "Dirancang untuk bisnis profesional dan toko online.",
    features: [
      "1 Programmer Back End - Laravel,Go",
      "1 Programmer Front End - ReactJS,Jquery",
      "1 Programmer Mobile - Flutter",
      "1 UI/UX Designer",
      "1 QA Tester",
      "Domain gratis 1 tahun",
      "Hosting gratis 1 tahun",
      "Desain custom",
      "Desain responsif",
      "Support teknis 3 bulan",
      "Integrasi API dan fitur custom",
      "Training penggunaan aplikasi"
    ],
    price: "Rp 10.000.000*",
    discountPrice: "Rp 14.000.000",
    phoneNumber: '628113117113',
    message: "Halo, saya tertarik dengan paket Mobile Maniac. Mohon informasi lebih lanjut."
  },
  {
    name: "Paket Ultimate Maniac",
    description: "Solusi lengkap untuk perusahaan besar.",
    features: [
      "1 Programmer Back End - Laravel,Go",
      "1 Programmer Front End - ReactJS,Jquery",
      "1 Programmer Mobile - Flutter",
      "1 UI/UX Designer",
      "1 QA Tester",
      "1 PM",
      "Domain gratis 2 tahun",
      "Hosting gratis 1 tahun",
      "Desain custom premium",
      "Support teknis 4 bulan",
      "Integrasi API dan fitur custom",
      "Training penggunaan aplikasi",
      "Full Support",
      "Deploy website and playstore",
      "Maintenance website",
    ],
    price: "Rp 12.000.000*",
    discountPrice: "Rp 18.000.000",
    phoneNumber: '628113117113',
    message: "Halo, saya tertarik dengan paket Ultimate Maniac. Mohon informasi lebih lanjut."
  },
];

const PriceCard = ({en,resultContactUs,styles}) => {
  const [open, setOpen] = useState(null);

  const handleToggle = (index) => {
    setOpen(open === index ? null : index);
  };

  return (
    <div className='mt-16 px-16 md:px-32'>
        <div id='price' className='text-center text-2xl font-bold mb-5'>Pilih Paket Jasa Websitemu</div>
        <p className='text-center mb-10 w-full mx-auto md:w-1/2'>Ubah bisnismu dengan website profesional yang memikat! Kami hadir untuk membuat website yang tak hanya menarik, tapi juga mendorong bisnismu tumbuh pesat. Hubungi kami sekarang dan dapatkan penawaran terbaik!</p>
        <div className='grid grid-cols-2 md:grid-cols-4 mx-auto'>
            {packages.map((pkg, index) => (
                <div
                key={index}
                className="border border-gray-200 shadow-lg rounded-lg p-6 flex flex-col justify-between"
                >
                <h3 className="text-xl font-bold mb-2">{pkg.name}</h3>
                <p className="text-gray-600 mb-4">{pkg.description}</p>
                <button
                    onClick={() => handleToggle(index)}
                    className="text-indigo-500 font-semibold mb-4"
                >
                    {open === index ? "Tutup Detail" : "Lihat Detail"}
                </button>
                {open === index && (
                    <ul className="list-disc list-inside mb-4 text-gray-600">
                    {pkg.features.map((feature, i) => (
                        <li key={i}>{feature}</li>
                    ))}
                    </ul>
                )}
                <div className="mt-auto">
                    <p className="text-lg font-bold text-first text-[20px] mb-4">{pkg.price} <span className='line-through text-[12px] text-gray-600'>{pkg.discountPrice}</span></p>
                    <button
                        onClick={() => {
                            const url = `https://wa.me/${pkg.phoneNumber}?text=${encodeURIComponent(pkg.message)}`;
                            window.open(url, "_blank");
                        }}
                        style={{ backgroundColor: styles.primaryColor }}
                        className={`w-full text-white py-2 px-4 rounded-lg hover:bg-indigo-600 transition`}
                    >
                        {en ? "Booking Now" : "Pesan Sekarang"}
                    </button>
                </div>
                </div>
            ))}
        </div>
    </div>
  )
}

export default PriceCard