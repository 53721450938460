export default function VisionMission({styles, resultAboutUs, isMobile, en = false}){

  const BgColor = styles.primaryColor ? `bg-[${styles.primaryColor}]` : `bg-blue-900`

  return (
    <>
      <div className={`min-h-screen ${BgColor} flex flex-col lg:flex-row gap-y-8 py-8`} style={{backgroundColor: styles.primaryColor}}>
        <div data-aos="fade-right" className="text-white basis-1/2 flex flex-col items-center">
          <div className="w-1/2">
            <h2 className="text-3xl text-center my-4 font-bold">
              { en ? 'Vision' : 'Visi' }
            </h2>
            <p className="text-justify" dangerouslySetInnerHTML={{__html: resultAboutUs.visi}}>
            </p>
          </div>
        </div>
        <div data-aos="fade-left" className="text-white basis-1/2 flex flex-col items-center">
          <div className="w-1/2">
            <h2 className="text-3xl text-center my-4 font-bold">
              { en ? 'Mission' : 'Misi' }
            </h2>
            <p className="text-justify" dangerouslySetInnerHTML={{__html: resultAboutUs.misi}}>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}