import React from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Avatar,
} from "@material-tailwind/react";
 
function StarIcon({className}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={`${className}`}
    >
      <path
        fillRule="evenodd"
        d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
        clipRule="evenodd"
      />
    </svg>
  );
}

function renderStars(rating) {
  const stars = [];
  for (let i = 0; i < 5; i++) {
    stars.push(
      <StarIcon 
        key={i} 
        className={i < rating ? "text-yellow-500 h-5 w-5" : "text-gray-300 h-5 w-5"} 
      />
    );
  }
  return stars;
}

const TestimoniesNew = ({resultTestimonies,loadingTestimonies,en}) => {
  return (
    <div id='testimonies' className='mt-16 px-16 md:px-32 py-10 bg-gradient-to-r from-[#4b5563] to-[#003580] text-white '>
        <div className='text-center text-2xl font-bold mb-10'>Testimonies</div>
        
        {loadingTestimonies ? (
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
                <div className="flex animate-pulse flex-wrap items-center gap-3">
                    <div className="grid h-36 w-36 place-items-center rounded-lg bg-gray-300">
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="h-12 w-12 text-gray-500"
                        >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                        />
                        </svg>
                    </div>
                    <div className="w-max">
                        <Typography
                        as="div"
                        variant="h1"
                        className="mb-4 h-3 w-56 rounded-full bg-gray-300"
                        >
                        &nbsp;
                        </Typography>
                        <Typography
                        as="div"
                        variant="paragraph"
                        className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                        >
                        &nbsp;
                        </Typography>
                        <Typography
                        as="div"
                        variant="paragraph"
                        className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                        >
                        &nbsp;
                        </Typography>
                        <Typography
                        as="div"
                        variant="paragraph"
                        className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                        >
                        &nbsp;
                        </Typography>
                        <Typography
                        as="div"
                        variant="paragraph"
                        className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                        >
                        &nbsp;
                        </Typography>
                        <Typography
                        as="div"
                        variant="paragraph"
                        className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                        >
                        &nbsp;
                        </Typography>
                        <Typography
                        as="div"
                        variant="paragraph"
                        className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                        >
                        &nbsp;
                        </Typography>
                    </div>
                </div>
                <div className="flex animate-pulse flex-wrap items-center gap-3">
                    <div className="grid h-36 w-36 place-items-center rounded-lg bg-gray-300">
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="h-12 w-12 text-gray-500"
                        >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.25 15.75l5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                        />
                        </svg>

                    </div>
                    <div className="w-max">
                        <Typography
                        as="div"
                        variant="h1"
                        className="mb-4 h-3 w-56 rounded-full bg-gray-300"
                        >
                        &nbsp;
                        </Typography>
                        <Typography
                        as="div"
                        variant="paragraph"
                        className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                        >
                        &nbsp;
                        </Typography>
                        <Typography
                        as="div"
                        variant="paragraph"
                        className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                        >
                        &nbsp;
                        </Typography>
                        <Typography
                        as="div"
                        variant="paragraph"
                        className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                        >
                        &nbsp;
                        </Typography>
                        <Typography
                        as="div"
                        variant="paragraph"
                        className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                        >
                        &nbsp;
                        </Typography>
                        <Typography
                        as="div"
                        variant="paragraph"
                        className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                        >
                        &nbsp;
                        </Typography>
                        <Typography
                        as="div"
                        variant="paragraph"
                        className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                        >
                        &nbsp;
                        </Typography>
                    </div>
                </div>
                <div className="flex animate-pulse flex-wrap items-center gap-3">
                    <div className="grid h-36 w-36 place-items-center rounded-lg bg-gray-300">
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="h-12 w-12 text-gray-500"
                        >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                        />
                        </svg>
                    </div>
                    <div className="w-max">
                        <Typography
                        as="div"
                        variant="h1"
                        className="mb-4 h-3 w-56 rounded-full bg-gray-300"
                        >
                        &nbsp;
                        </Typography>
                        <Typography
                        as="div"
                        variant="paragraph"
                        className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                        >
                        &nbsp;
                        </Typography>
                        <Typography
                        as="div"
                        variant="paragraph"
                        className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                        >
                        &nbsp;
                        </Typography>
                        <Typography
                        as="div"
                        variant="paragraph"
                        className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                        >
                        &nbsp;
                        </Typography>
                        <Typography
                        as="div"
                        variant="paragraph"
                        className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                        >
                        &nbsp;
                        </Typography>
                        <Typography
                        as="div"
                        variant="paragraph"
                        className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                        >
                        &nbsp;
                        </Typography>
                        <Typography
                        as="div"
                        variant="paragraph"
                        className="mb-2 h-2 w-72 rounded-full bg-gray-300"
                        >
                        &nbsp;
                        </Typography>
                    </div>
                </div>                
            </div>
        ):(
           <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {resultTestimonies.length > 0 ? (
                resultTestimonies.map((testimony, index) => (
                <Card
                    color="transparent"
                    shadow={false}
                    className="w-full max-w-[26rem]"
                    key={index}
                >
                    <CardHeader
                    color="transparent"
                    floated={false}
                    shadow={false}
                    className="mx-0 flex items-center gap-4 pt-0 pb-8"
                    >
                    <Avatar
                        size="lg"
                        variant="circular"
                        src={`https://cmsfg.fhadirainovasiteknologi.id/assets/images/testimony/${testimony.file}`}
                        alt="Gambar testimoni"
                    />
                    <div className="flex w-full flex-col gap-0.5">
                        <div className="flex items-center justify-between">
                        <Typography variant="h5" color="white">
                            {testimony.name ?? "No name"}
                        </Typography>
                        <div className="flex items-center gap-1">
                            {renderStars(testimony.rating ?? 0)}
                        </div>
                        </div>
                        <Typography color="white">
                        {testimony.title ?? "No position"}
                        </Typography>
                    </div>
                    </CardHeader>
                    <CardBody className="mb-6 p-0">
                    <Typography
                        color="white"
                        dangerouslySetInnerHTML={{
                        __html: testimony.description ?? "No content",
                        }}
                    ></Typography>
                    </CardBody>
                </Card>
                ))
            ) : (
                <div className="text-center col-span-3 text-2xl font-bold mb-10">
                {en
                    ? "There are no testimonials available yet"
                    : "Belum ada testimoni yang tersedia"}
                </div>
            )}
</div>

        )}
    </div>
  )
}

export default TestimoniesNew