import React from 'react';
import {
  Navbar,
  Collapse,
  Typography,
  Button,
  IconButton,
} from "@material-tailwind/react";
import ReactCountryFlag from 'react-country-flag';
import { Link } from 'react-router-dom';

const HeaderNew = ({resultAboutUs, en,styles}) => {
  const [openNav, setOpenNav] = React.useState(false);
 
  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, []);
 
  const navList = (
    <ul className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      <Typography
        as="li"
        variant="small"
        color="gray"
        className="p-1 font-normal"
      >
        <Link to={'/'} className="flex items-center">
          Home
        </Link>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="gray"
        className="p-1 font-normal"
      >
        <a href={en ? `/en#portfolio` : `/#portfolio`} className="flex items-center">
          { en ? 'Portfolio' : 'Portofolio' }
        </a>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="gray"
        className="p-1 font-normal"
      >
        <a href={en ? `/en#testimonies` : `#testimonies`} className="flex items-center">
          { en ? 'Testimony' : 'Testimoni' }
        </a>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="gray"
        className="p-1 font-normal"
      >
        <a href={ en ? '/en#news' : '#news' } className="flex items-center">
          { en ? 'News' : 'Berita' }
        </a>
      </Typography>
    </ul>
  );
 
  return (
    <Navbar className="sticky top-0 z-10 h-max max-w-full rounded-none px-12 md:px-28 py-2 lg:py-4">
      <div className="flex items-center justify-between text-gray-900">
        <Typography
          as="a"
          href="#"
          className="mr-4 cursor-pointer py-1.5 font-medium"
        >
          <img src={`https://cmsfg.fhadirainovasiteknologi.id/assets/images/aboutUs/${resultAboutUs.logo}`} alt="Logo" width="100"/>
        </Typography>
        <div className="flex items-center gap-4">
          <div className="mr-4 hidden lg:block">{navList}</div>
          <div className="flex items-center gap-x-1">
            <Link to="/">
              <Button
                variant={en ? 'text' : 'filled'}
                size="sm"
                className="hidden lg:inline-block"
              >
                <span className='normal-case text-[14px]'><ReactCountryFlag countryCode="ID" svg /> Indonesia</span>
              </Button>
            </Link>
            <Link to={'/en'}>
              <Button
                variant={en ? 'filled' : 'text'}
                size="sm"
                className="hidden lg:inline-block"
              >
                <span className='normal-case text-[14px]'> <ReactCountryFlag countryCode="GB" svg /> Inggris</span>
              </Button>
            </Link>
          </div>
          <IconButton
            variant="text"
            className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                className="h-6 w-6"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </IconButton>
        </div>
      </div>
      <Collapse open={openNav}>
        {navList}
        <div className="flex items-center gap-x-1">
          <Link to={'/en'}>
            <Button 
              variant={en ? 'text' : 'filled'}
              size="sm"
              className="inline-block lg:hidden"
            >
              <span className='normal-case text-[14px]'><ReactCountryFlag countryCode="ID" svg /> Indonesia</span>
            </Button>
          </Link>
          <Link to={'/en'}>
            <Button
              variant={en ? 'filled' : 'text'}
              size="sm"
              style={{ backgroundColor: styles.primaryColor }}
              className={`inline-block lg:hidden`}
            >
              <span className='normal-case text-[14px]'> <ReactCountryFlag countryCode="GB" svg /> Inggris</span>
            </Button>
          </Link>
        </div>
      </Collapse>
    </Navbar>
  );
}

export default HeaderNew