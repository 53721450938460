// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import { Link } from 'react-router-dom';

export default function News({resultArticles, newsTitle = 'News', namapartner = 'Fitek'}){

  const isMobile = window.innerWidth < 758

  return (
    <>
      <div id='news' className="h-screen">
        <div data-aos="fade-left"  className="h-full flex flex-col justify-center w-5/6 mx-auto">
          <h2 className="text-center font-bold text-3xl my-4">
            {newsTitle}
          </h2>
          <div>
            <Swiper
              spaceBetween={50}
              slidesPerView={isMobile ? 1 : 3}
              // onSlideChange={() => console.log('slide change')}
              // onSwiper={(swiper) => console.log(swiper)}
            >
              {
                  resultArticles.map(articles => (
                    <SwiperSlide>
                      <Link to={`/${namapartner}/news/${articles.slug}`}>
                        <div className="aspect-square bg-cover bg-center flex items-end justify-center rounded-lg px-4 py-4 hover:scale-125 hover:transition-all" style={{backgroundImage: `url(https://cmsfg.fhadirainovasiteknologi.id/assets/images/news/${articles.file})`}}>
                          <h3 className="text-white font-bold hover:text-white/50 uppercase text-xl">
                            {`${articles.name}`}
                          </h3>
                        </div>
                      </Link>
                    </SwiperSlide>
                  ))
              }
            </Swiper>
          </div>
        </div>
      </div>
    </>
  )
}