import React from 'react'
  const inspector = '../assets/inspector.png'
  const pintarkerja = '../assets/pintarkerja.png'
  const specta = '../assets/specta.png'
  const alita = '../assets/alita.png'
  const jateng = '../assets/jateng.png'
  const xlaxiata = '../assets/xlaxiata.png'

const OurClient = ({en,styles}) => {
  return (
    <div className="flex flex-col justify-center px-32 mt-16">
        <div className="text-xl text-center text-gray-700 dark:text-white">
        {en ? 'Our Beloved' : 'Pelanggan Tercinta'} <span className={`text-[${styles.primaryColor}]`}>{en ? 'Customers' : 'Kami'}</span>{" "}
        
        </div>

        <div className="flex flex-wrap justify-center gap-5 mt-10 md:justify-around">
        <div className="grayscale hover:grayscale-0">
            <img src={inspector} alt="" width="80px"/>
        </div>
        <div className="grayscale hover:grayscale-0">
            <img src={pintarkerja} alt="" width="78px"/>
        </div>
        <div className="grayscale hover:grayscale-0">
            <img src={alita} alt="" width="120px"/>
        </div>
        <div className="pt-1 grayscale hover:grayscale-0">
            <img src={specta} alt="" width="120px"/>
        </div>
        <div className="pt-1 grayscale hover:grayscale-0">
            <img src={xlaxiata} alt="" width="130px"/>
        </div>
        <div className="pt-1 grayscale hover:grayscale-0">
            <img src={jateng} alt="" width="70px"/>
        </div>
        </div>
    </div>
  )
}

export default OurClient