
import LinkedinImage from "../img/Compro FG/linkedin_blue.svg"
import TwitterImage from "../img/Compro FG/twitter_blue.svg"
import FacebookImage from "../img/Compro FG/facebook_blue.svg"
import InstagramImage from "../img/Compro FG/instagram_blue.svg"
import YoutubeImage from "../img/Compro FG/youtube_blue.svg"
import { Breadcrumbs } from "@material-tailwind/react"
import { Link } from "react-router-dom"

export default function NewsInformation({resultContactUs, resultNews}){

  const InstagramLink = `https://www.instagram.com/${resultContactUs.instagram}`
  const TwitterLink = `https://www.x.com/${resultContactUs.twitter}`
  const LinkedinLink = `https://www.linkedin.com/in/${resultContactUs.linkedin}`  

  console.log(resultNews);
  

  return (
    <>
      <div className="min-h-screen py-4 w-4/5 mx-auto flex flex-col gap-y-4 mt-5">
      
        <h2 className="text-center font-bold text-3xl">
          {resultNews.name}
        </h2>
        <div className="flex flex-row justify-center">
          <img src={`https://cmsfg.fhadirainovasiteknologi.id/assets/images/news/${resultNews.file}`} alt="" className="aspect-video w-full rounded-3xl" />
        </div>
        <div className="flex flex-row justify-between">
          <div>
            <p className="text-center text-sm">
              <Breadcrumbs>
                <Link to="/" className="opacity-60">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                  </svg>
                </Link>
                <a href="#" className="opacity-60">
                  <span>News</span>
                </a>
                <a href="#">{resultNews.name}</a>
              </Breadcrumbs>
            </p>
          </div>
          <div className="flex flex-row items-center gap-x-4">
            <span>
              Bagikan :
            </span>
            <a href={InstagramLink} target="_blank" className="hover:opacity-50">
              <img src={InstagramImage} alt="" />
            </a>
            <a href={InstagramLink} target="_blank" className="hover:opacity-50">
              <img src={FacebookImage} alt="" />
            </a>
            <a href={LinkedinLink} target="_blank" className="hover:opacity-50">
              <img src={LinkedinImage} alt="" />
            </a>
            <a href={InstagramLink} target="_blank" className="hover:opacity-50">
              <img src={YoutubeImage} alt="" />
            </a>
            <a href={TwitterLink} target="_blank" className="hover:opacity-50">
              <img src={TwitterImage} alt="" />
            </a>
          </div>
        </div>
        <div dangerouslySetInnerHTML={{__html: resultNews.description}}>
        </div>
      </div>
    </>
  )
}