import React, { useEffect, useState } from 'react'
import HeaderNew from '../../component/HeaderNew'
import BannerNew from '../../component/BannerNew'
import OurClient from '../../component/OurClient'
import VisionMissionNew from '../../component/VisionMissionNew'
import PortofolioNew from '../../component/PortofolioNew'
import TestimoniesNew from '../../component/TestimoniesNew'
import NewsNew from '../../component/NewsNew'
import FooterNew from '../../component/FooterNew'
import ScrollToTop from 'react-scroll-to-top'
import axios from 'axios'
import FaqNew from '../../component/FaqNew'
import { useLocation } from 'react-router-dom'
import PriceCard from '../../component/PriceCard'

const api = axios.create({
    baseURL: `https://cmsfg.fhadirainovasiteknologi.id/api`
})
const NewLandingPage = () => {
  const [result, setResult] = useState([]);
  const [loadingResult, setLoadingResult] = useState(true);
  const [resultAboutUs, setResultAboutUs] = useState([]);
  const [loadingResultAboutUs, setLoadingResultAboutUs] = useState(true);
  const [resultArticles, setResultArticles] = useState([]);
  const [loadingArticles, setLoadingArticles] = useState(true);
  const [resultTestimonies, setResultTestimonies] = useState([]);
  const [loadingTestimonies, setLoadingTestimonies] = useState(true);
  const [resultPorto, setResultPorto] = useState([]);
  const [loadingPorto, setLoadingPorto] = useState(true);
  const [resultContactUs, setResultContactUs] = useState([]);
  // const [loadingContactUs, setLoadingContactUs] = useState(true);
  const [resultFaq, setResultFaq] = useState([]);
  const [loadingFaq, setLoadingFaq] = useState(true);
  const styles = {
      primaryColor: `${result.primary_color}`,
      secondaryColor: `${result.secondary_color}`,
  };
  const en = useLocation().pathname.includes('/en');

  useEffect(() => {
      // Fungsi untuk memanggil API
      const fetchData = async () => {
          try {
            await api.get('/partners?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=Fitek').then(response => {
              setResult(response.data.data); // Mengatur hasil response ke state
              setLoadingResult(false); // Menghentikan loading
            }); // Ganti '/endpoint' dengan path API yang sesuai

            await api.get('about-us?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=Fitek').then(response => {
              setResultAboutUs(response.data.data); // Mengatur hasil response ke state
              setLoadingResultAboutUs(false); // Menghentikan loading
            }); // Ganti '/endpoint' dengan path API yang sesuai

            await api.get('articles?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=Fitek').then(response => {
              setResultArticles(response.data.data); // Mengatur hasil response ke state
              setLoadingArticles(false);
            }); // Ganti '/endpoint' dengan path API yang sesuai

            await api.get('testimonies?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=Fitek').then(response => {
              setResultTestimonies(response.data.data); // Mengatur hasil response ke state
              setLoadingTestimonies(false);
            }); // Ganti '/endpoint' dengan path API yang sesuai

            await api.get('products?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=Fitek').then(response => {
              setResultPorto(response.data.data); // Mengatur hasil response ke state
         
              setLoadingPorto(false);
            }); // Ganti '/endpoint' dengan path API yang sesuai

            await api.get('contact-us?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=Fitek').then(response => {
              setResultContactUs(response.data.data); // Mengatur hasil response ke state
              // setLoadingContactUs(false);
            }); // Ganti '/endpoint' dengan path API yang sesuai

            await api.get('faq?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=Fitek').then(response => {
              setResultFaq(response.data.data); // Mengatur hasil response ke state
              setLoadingFaq(false);
            }); // Ganti '/endpoint' dengan path API yang sesuai
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoadingResult(false);
            setLoadingResultAboutUs(false);
            setLoadingArticles(false);
            setLoadingTestimonies(false);
            setLoadingPorto(false);
            // setLoadingContactUs(false);
            setLoadingFaq(false);
          }
      };

      // Panggil fungsi fetch saat komponen di-mount
      fetchData();
      window.scrollTo(0, 0)
  }, []); // Dependensi kosong agar hanya dipanggil sekali saat komponen di-mount

  return (
    <>
    <div className='-m-6 max-h-[768px] w-[calc(100%+48px)]'>
      <HeaderNew styles={styles} resultAboutUs={resultAboutUs} en={en}></HeaderNew>
      <BannerNew loadingResult={loadingResult} loadingResultAboutUs={loadingResultAboutUs} result={result} resultContactUs={resultContactUs} resultAboutUs={resultAboutUs} en={en}></BannerNew>
      <OurClient styles={styles} en={en}></OurClient>
      <VisionMissionNew styles={styles} resultAboutUs ={resultAboutUs} loadingResultAboutUs ={loadingResultAboutUs} en={en}></VisionMissionNew>
      <PortofolioNew resultAboutUs={resultAboutUs} resultPorto={resultPorto} loadingPorto={loadingPorto} en={en}></PortofolioNew>
      <PriceCard styles={styles} resultContactUs={resultContactUs} en={en}></PriceCard>
      <TestimoniesNew resultTestimonies={resultTestimonies} loadingTestimonies={loadingTestimonies} en={en}></TestimoniesNew>
      <NewsNew resultArticles = {resultArticles} loadingArticles = {loadingArticles} en={en}></NewsNew>
      <FaqNew styles={styles} loadingFaq={loadingFaq} resultFaq={resultFaq} en={en}></FaqNew>
      <FooterNew styles={styles} resultContactUs={resultContactUs}></FooterNew>

      <ScrollToTop className='flex flex-row justify-center items-center hover:transition-all hover:scale-125' smooth />
    </div>
    </>
  )
}

export default NewLandingPage