import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Navbar from "../../component/Header"
import Banner from "../../component/Banner"
import { useParams } from 'react-router-dom';
import NewsInformation from '../../component/NewsInformation';
import NewsAnother from '../../component/NewsAnother';
import Footer from '../../component/Footer';
import News from '../../component/News';

const api = axios.create({
  baseURL: `https://cmsfg.fhadirainovasiteknologi.id/api`
})

export default function NewsPage(){
  
  const [result, setResult] = useState([]);
  const [resultAboutUs, setResultAboutUs] = useState([]);
  const [resultContactUs, setResultContactUs] = useState([]);
  const [resultNews, setResultNews] = useState([]);
  const [resultArticles, setResultArticles] = useState([]);

  let { namapartner, slug } = useParams();
  
  const isMobile = window.innerWidth < 768;
  
  const styles = {
    primaryColor: `${result.primary_color}`,
    secondaryColor: `${result.secondary_color}`,
    // ... other styles
  };

  useEffect(() => {
    // Fungsi untuk memanggil API
    const fetchData = async () => {
        try {
            namapartner = namapartner ?? 'Fitek';
            // console.log('nama partner: ' + namapartner);
            const response = await api.get('/partners?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel= ' + `${namapartner}`); // Ganti '/endpoint' dengan path API yang sesuai
            setResult(response.data.data); // Mengatur hasil response ke state
            // console.log(response.data);

            const responseAboutUs = await api.get('about-us?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=' + `${namapartner}`); // Ganti '/endpoint' dengan path API yang sesuai
            setResultAboutUs(responseAboutUs.data.data); // Mengatur hasil response ke state
            // console.log(responseAboutUs.data);

            const responseContactUs = await api.get('contact-us?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=' + `${namapartner}`); // Ganti '/endpoint' dengan path API yang sesuai
            setResultContactUs(responseContactUs.data.data); // Mengatur hasil response ke state
            // console.log(responseContactUs.data);

            const responseNews = await api.get(`articles/${slug}?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=` + `${namapartner}`); // Ganti '/endpoint' dengan path API yang sesuai
            setResultNews(responseNews.data.data); // Mengatur hasil response ke state
            // console.log(responseNews.data);

            const responseArticles = await api.get('articles?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=' + `${namapartner}`); // Ganti '/endpoint' dengan path API yang sesuai
            setResultArticles(responseArticles.data.data); // Mengatur hasil response ke state
            // console.log(responseArticles.data);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

      // Panggil fungsi fetch saat komponen di-mount
      fetchData();
      window.scrollTo(0, 0)
  }, []); // Dependensi kosong agar hanya dipanggil sekali saat komponen di-mount

  return (
    <>
      <Navbar resultAboutUs={resultAboutUs}></Navbar>
      {/* <Banner result={result} resultAboutUs={resultAboutUs}></Banner> */}
      <NewsInformation resultContactUs={resultContactUs} resultNews={resultNews}></NewsInformation>
      <hr/>
      {/* <NewsAnother isMobile={isMobile}></NewsAnother> */}
      <News resultArticles={resultArticles} newsTitle={`Berita Lainnya`}/>
      <Footer styles={styles} resultContactUs={resultContactUs} isMobile={isMobile}></Footer>
    </>
  )
}