import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Banner from '../../component/Banner'
import OurMember from '../../component/OurMember'
import Footer from '../../component/Footer'

const api = axios.create({
  baseURL: `https://cmsfg.fhadirainovasiteknologi.id/api`
})

const LandingPage = () => {

  const [result, setResult] = useState([]);
  const [partner, setPartner] = useState([]);
  const [contactUs, setContactUs] = useState([]);

  useEffect(() => {
    // Fungsi untuk memanggil API
    const fetchData = async () => {
      try {
        const response = await api.get('/banners?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=FG'); // Ganti '/endpoint' dengan path API yang sesuai
        setResult(response.data.data); // Mengatur hasil response ke state
        console.log(response.data);

        const partnerResponse = await api.get('/partners?safety_key=M1n0r_P34c3_0f_C4l4mity'); // Ganti '/endpoint' dengan path API yang sesuai
        setPartner(partnerResponse.data.data);
        console.log(partnerResponse.data);


        const contactUsResponse = await api.get('/contact-us?safety_key=M1n0r_P34c3_0f_C4l4mity'); // Ganti '/endpoint' dengan path API yang sesuai
        setContactUs(contactUsResponse.data.data);
        console.log(contactUsResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Panggil fungsi fetch saat komponen di-mount
    fetchData();
  }, []); // Dependensi kosong agar hanya dipanggil sekali saat komponen di-mount


  return (
    <>
      <Banner result={result}></Banner>
      <OurMember partner={partner} en={true}></OurMember>
      <Footer phone={contactUs.mobile_phone} email={contactUs.email} address={contactUs.address} en={true}></Footer>
    </>
  )
}

export default LandingPage
