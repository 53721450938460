import React, { useEffect, useState } from 'react';
import './career.css';
import Header from '../../../../components/header/en/header';
import FooterFGMembers from '../../../../components/footer/en/footer-fga-members';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import CareerCard from '../../../../components/cards/en/career-card';
const api = axios.create({
    baseURL: `https://cmsfg.fhadirainovasiteknologi.id/api`
})
const CareerPage = () => {
    const [result, setResult] = useState([]);
    const [resultAboutUs, setResultAboutUs] = useState([]);
    const [resultContactUs, setResultContactUs] = useState([]);
    const [resultCareers, setResultCareers] = useState([]);
    const { namapartner } = useParams();

    useEffect(() => {
        // Function to call the API
        const fetchData = async () => {
            try {
                const response = await api.get('/partners?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel= ' + `${namapartner}`); // Change '/endpoint' with the appropriate API path
                setResult(response.data.data); // Set the response result to state
                console.log(response.data);

                const responseAboutUs = await api.get('about-us?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=' + `${namapartner}`); // Ganti '/endpoint' dengan path API yang sesuai
                setResultAboutUs(responseAboutUs.data.data); // Mengatur hasil response ke state
                console.log(responseAboutUs.data);


                const responseContactUs = await api.get('contact-us?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=' + `${namapartner}`); // Change '/endpoint' with the appropriate API path
                setResultContactUs(responseContactUs.data.data); // Set the response result to state
                console.log(responseContactUs.data);

                const responseCareer = await api.get('careers?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=' + `${namapartner}`); // Ganti '/endpoint' dengan path API yang sesuai
                setResultCareers(responseCareer.data.data); // Mengatur hasil response ke state
                console.log(responseCareer.data);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        // Call the fetch function when the component mounts
        fetchData();
        window.scrollTo(0, 0)
    }, []); // Empty dependency so it's only called once when the component mounts

    const styles = {
        primaryColor: `${result.primary_color}`,
        secondaryColor: `${result.secondary_color}`,
        // ... other styles
    };

    return (
        <div>
            {/* <Header logoUrl={`https://cmsfg.fhadirainovasiteknologi.id/assets/images/aboutUs/${resultAboutUs.logo}`} namapartner={namapartner}/> */}
          <div className='banner-career'>
            <h1 className='poppins-bold'>Are you the person</h1>
            <h1 className='poppins-bold'>we've been looking for?</h1>
            <p className='poppins-medium'>We are proud to work with individuals who are passionate about learning and enjoy sharing <br/>knowledge with others. Apply and become a part of our family.</p>
            <p></p>
          </div>
          <div className='job-list'>
            <div className='job-list-text'>
                <h1 className='poppins-bold'> Job List </h1>
                <p className='poppins-medium'>We are a software house company based in Bekasi. We offer business solution <br/> and have been trusted by 20+ professional clients. Get career opportunities and grow together <br/> with innovative people and work together with many companies across Indonesia.</p>
            </div>
          </div>

          <div className='grid-container'>
        {
          resultCareers.map(career => (
            <CareerCard className='grid-item' title={"Tersedia"} subtitle={career.name} text={career.long_description}/>
          ))
        }
      </div>
          {/* <div className='grid-container'>
            <CareerCard className='grid-item'title={'Full-time'} subtitle={'Frontend Developer'} text={'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s'}/>
            <CareerCard className='grid-item'title={'Full-time'} subtitle={'Backend Developer'} text={'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s'}/>
            <CareerCard className='grid-item'title={'Full-time'} subtitle={'Mobile Developer'} text={'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s'}/>
            <CareerCard className='grid-item'title={'Full-time'} subtitle={'Administrator Officer'} text={'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s'}/>
            <CareerCard className='grid-item'title={'Full-time'} subtitle={'Administrator Officer'} text={'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s'}/>
            <CareerCard className='grid-item'title={'Full-time'} subtitle={'Administrator Officer'} text={'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s'}/>
          </div> */}
          <FooterFGMembers backgroundColor={styles.primaryColor} twitter={resultContactUs.twitter} instagram={resultContactUs.instagram} linkedin={resultContactUs.linkedln} email={resultContactUs.email}/>
        </div>
    )
}

export default CareerPage;
